<template>
  <div class="step---wrapper">
    <div class="step--content">
      <div class="page-title">汇款金额：</div>
      <div class="page-title-tip">友情提示：因平台财务与保司核保需要一定时间处理，为保证保单及时生效，建议您在最优付款倒计时内完成付款操作。</div>
      <div class="transfer-content border-box">
        <div class="left">
          <div class="left-label">最优时间付款倒计时：</div>
          <a-statistic-countdown :value="deadline" format="D 天 H 时 m 分 s 秒" />
        </div>
        <div class="right">
          <div class="money-box">
            <div class="money">{{ payNumber }}</div>
            <div class="unit">元</div>
          </div>
        </div>
      </div>
      <div class="page-title">唯一指定付款账户：</div>
      <div class="transfer-info border-box">
        <div class="label-content">
          <div class="info-label">对公银行账户号：</div>
          <div class="info-label">账户主体：</div>
          <div class="info-label">电话：</div>
          <div class="info-label">开户行信息：</div>
          <div class="info-label">开户行行号：</div>
        </div>
        <div class="value-content">
          <div class="info-value">5329 1314 4010 366</div>
          <div class="info-value">鼎基智能云平台有限公司</div>
          <div class="info-value">0532-55786657</div>
          <div class="info-value">招商银行青岛国际创新园支行</div>
          <div class="info-value">3084 5202 5510</div>
        </div>
        <div class="copy-btn" @click="copy">复制打款信息</div>
      </div>
      <div class="page-btn">
        <a-button type="primary" @click="handleUpload" v-perms="'upload'">上传打款凭证</a-button>
      </div>
    </div>
    <uploadPayment ref="uploadPayment"/>
  </div>
</template>

<script>

import uploadPayment from './upload-payment.vue'
import moment from 'moment'
export default {
  name: 'StepPage',
  inject: ['handleCopy', 'getOrderData'],
  components: {
    uploadPayment
  },
  computed: {
    orderData () {
      return this.getOrderData()
    },
    payNumber () {
      return this.orderData.sumPremium
    },
    deadline () {
      return (moment(this.orderData.createTime).format('x') * 1) + 1000 * 60 * 60 * 24 * 2 + 1000 * 30
    }
  },
  data () {
    return {
      // deadline: Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30
    }
  },
  methods: {
    copy () {
      const text = `对公银行账户号：5329 1314 4010 366, 账户主体：鼎基智能云平台有限公司, 电话：0532-55786657, 开户行信息：招商银行青岛国际创新园支行, 开户行行号：3084 5202 5510`
      this.handleCopy(text)
    },
    handleUpload () {
      this.$refs.uploadPayment.$init(this.orderData.orderNo)
    }
  }
}
</script>

<style lang="less">
.step---wrapper {
  height: 100%;

  .step--content {
    width: 820px;
    margin: 30px auto;
    color: #303133;
    .border-box {
      border: 1px solid #c4c4c4;
      padding: 15px 20px;
    }
    .page-title {
      font-weight: 550;
      font-size: 18px;
      margin-bottom: 12px;
    }
    .page-title-tip{
      margin-bottom: 12px;
      color: #ff4848;
    }

    .transfer-content {
      display: flex;
      margin-bottom: 44px;
      .left {
        text-align: center;
        font-size: 16px;
        width: 260px;
        &-value {
          font-weight: 500;
          margin-bottom: 10px;
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .money-box {
          display: flex;
          align-items: baseline;
          .money {
            font-size: 36px;
            font-weight: 550;
            color: #ff4848;
            margin-right: 16px;
          }
        }
      }
    }

    .transfer-info {
      display: flex;
      align-items: baseline;
      font-size: 16px;
      font-weight: 400;
      .label-content {
        margin-bottom: 13px;
        width: 130px;
        text-align: right;
        font-weight: 500;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .value-content {
        margin-bottom: 13px;
        flex: 1;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .copy-btn {
        color: #3d77ff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .page-btn {
    margin-top: 65px;
    text-align: center;
  }
}
</style>
